import { useState } from "react";
import Button from "elements/Button";
import Input from "elements/Input";
import AuthLayout from "views/Layout/Auth";
import { LINKS } from "const/routes";

const SignUp = () => {
    const [form, setForm] = useState({
        email: "",
        pass: ""
    });

    const handleInputChange = (e) =>{
        setForm({
            ...form, 
            [e.target.name]: e.target.value        
        });
    }

    return(
        <AuthLayout>
            <div className="Auth-Form">
                <h1>Registro</h1>
                <Input
                    label="Nombre Completo"
                    value={form.name}
                    onChange={handleInputChange}
                    name="name"
                />
                <Input
                    label="Correo Electrónico"
                    value={form.email}
                    onChange={handleInputChange}
                    name="email"
                    type="email"
                />
                <Input
                    label="Contraseña"
                    value={form.pass}
                    onChange={handleInputChange}
                    name="pass"
                    type="password"
                />
                <Input
                    label="Confirmar Contraseña"
                    value={form.pass}
                    onChange={handleInputChange}
                    name="pass_confirm"
                    type="password"
                />
                <Button style={{width:"100%", textAlign: "center"}}>
                    Registrarse
                </Button>
                <p>¿Ya tienes cuenta? <Button href={LINKS.AUTH.SIGN_IN}>Inicia sesión aquí</Button></p>
            </div>
        </AuthLayout>
    )
}
export default SignUp