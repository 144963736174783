import Input from "elements/Input"
import { useState } from "react"
import AuthLayout from "views/Layout/Auth"
import "../Auth.scss"
import Button from "elements/Button"
import { LINKS } from "const/routes"

const SignIn = ({}) => {
    const [form, setForm] = useState({
        email: "",
        pass: ""
    });

    const handleInputChange = (e) =>{
        setForm({
            ...form, 
            [e.target.name]: e.target.value        
        });
    }

    return(
        <AuthLayout>
            <div className="Auth-Form">
                <h1>Iniciar Sesión</h1>
                <Input
                    label="Correo Electrónico"
                    value={form.email}
                    onChange={handleInputChange}
                    name="email"
                />
                <Input
                    label="Contraseña"
                    value={form.pass}
                    onChange={handleInputChange}
                    name="pass"
                    type="password"
                />
                <Button style={{width:"100%", textAlign: "center"}}>
                    Iniciar Sesión
                </Button>
                <p>¿No tienes cuenta todavía? <Button href={LINKS.AUTH.SIGN_UP}>Crea una aquí</Button></p>
                
            </div>
        </AuthLayout>
    )
}
export default SignIn