import "./AuthLayout.scss";

const AuthLayout = ({children}) => {
    return(
        <div className="AuthLayout">
            <div className="AuthLayout-Content">
                <div className="AuthLayout-Form">
                    {children}
                </div>
            </div>
            <div className="AuthLayout-SideCover" style={{backgroundImage:"url('https://picsum.photos/800')"}}></div>
        </div>
    )
}
export default AuthLayout