import "./Button.scss";
import { Link } from "react-router-dom"

const Button = ({href, styled, onClick, children, style}) => {
    let classNames = "ElmButton";
    classNames = (href && !styled) ? "BasicLink" : classNames

    return(
        <>
            { href ?
                <Link
                    className={classNames}
                    to={href}
                    style={style}
                    >
                    {children}
                </Link>
            :
                <button
                    className={classNames}
                    onClick={onClick}
                    style={style}
                    >
                    {children}
                </button>
            }
        </>
    )
}
export default Button