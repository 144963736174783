import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "views/pages/Home"
import {LINKS} from "const/routes"
import SignIn from 'views/pages/Auth/SignIn';
import SignUp from 'views/pages/Auth/SignUp';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={LINKS.HOME} element={<Home />} />
                <Route path={LINKS.AUTH.SIGN_IN} element={<SignIn />} />
                <Route path={LINKS.AUTH.SIGN_UP} element={<SignUp />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
