import BaseLayout from "views/Layout/Base";
import "./Home.scss";

const Home = () => {
    return(
        <BaseLayout>
            <div>
                Welcome to Puntotodo
            </div>
        </BaseLayout>
    )
}
export default Home