import "./Input.scss";

const Input = ({label, value, onChange, type, name}) =>{
    return(
        <div className="ElmInput">
            <input
                className="ElmInput-Input"
                type={type ? type : "text"}
                value={value}
                onChange={onChange}
                placeholder={label}
                name={name}
            />
        </div>
    )
}
export default Input